/* eslint-disable */
<template>
    <div>
        <v-container v-if="pricing && pricing.name" fluid>
            <v-row>
                <v-col cols="12" md="4">
                    <PrincingDetail :name="pricing.name || ''" :billingCycle="selectedBillingCycle"
                        :cardClass="pricing.cardClass || ''" :amount="totalAmount"
                        :quantity="Math.abs(Number(quantity))" :details="details" />

                </v-col>
                <v-col cols="12" md="8">
                    <div class="container">
                        <div>
                            <div>
                                <h1>1. Subscription Customization</h1>
                                <p>Customize your Subscription Plan to fit your needs.</p>

                            </div>
                            <v-row>
                                <v-col cols="12" md="3">
                                    <div>
                                        <v-select :items="pricingNames" v-model="selectedPricingName" label="Your plan"
                                            outlined></v-select>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <div>
                                        <v-select :items="billingCycle" v-model="selectedBillingCycle"
                                            label="Billing Cycle" outlined></v-select>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <div>
                                        <v-text-field v-model="quantity" @change="onQuantityChange" type="number"
                                            :label="`Quantity of ${billingCycle}`" outlined></v-text-field>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="3" class="d-flex justify-center align-center ">
                                    <div class="step1-pricing">
                                        <span> $ {{ pricingAmount }}</span>
                                    </div>
                                    <span class="ml-2">/ {{ selectedBillingCycle }}</span>
                                </v-col>
                            </v-row>

                            <div class="mt-5">
                                <div>
                                    <div class="d-flex justify-space-between ">
                                        <h2 class="mb-2">Workspace</h2>
                                        <div class="font-weight-bold">
                                            <span> $ {{ additionalWorkspaceAmount }}</span>
                                            <span class="ml-2">({{ Math.abs(quantity) }} {{ selectedBillingCycle
                                                }})</span>
                                        </div>
                                    </div>

                                    <v-divider></v-divider>
                                    <div class="mt-3">

                                        <div>
                                            <div class="">
                                                <span class="font-weight-bold">Max Workspace</span>
                                            </div>
                                            <div>
                                                <v-list shaped>
                                                    <v-list-item-group v-model="selectedMaxWorkspace" color="primary">
                                                        <v-list-item class="border-grey  mb-1 px-5 px-md-5" link
                                                            v-for="item, i in pricing.workspaceAdditionalSubscriptions"
                                                            :key="i">


                                                            <v-list-item-content>
                                                                <v-list-item-title
                                                                    v-text="item.name"></v-list-item-title>
                                                            </v-list-item-content>

                                                            <v-list-item-icon>
                                                                <span class="font-weight-bold"> {{ item.amount }}</span>
                                                            </v-list-item-icon>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="">
                                                <span class="font-weight-bold">Max Member per workspace</span>
                                            </div>
                                            <div>
                                                <v-list shaped>
                                                    <v-list-item-group v-model="selectedMaxMemberPerWorkspace"
                                                        color="primary">
                                                        <v-list-item class="border-grey  mb-1 px-5 px-md-5" link
                                                            v-for="item, i in pricing.memberPerWorkspaceAdditionalSubscriptions"
                                                            :key="i">


                                                            <v-list-item-content>
                                                                <v-list-item-title
                                                                    v-text="item.name"></v-list-item-title>
                                                            </v-list-item-content>

                                                            <v-list-item-icon>
                                                                <span class="font-weight-bold"> {{ item.amount }}</span>
                                                            </v-list-item-icon>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="">
                                                <span class="font-weight-bold">Max Project per workspace</span>
                                            </div>
                                            <div>
                                                <v-list shaped>
                                                    <v-list-item-group v-model="selectedMaxProjectPerWorkspace"
                                                        color="primary">
                                                        <v-list-item class="border-grey  mb-1 px-5 px-md-5" link
                                                            v-for="item, i in pricing.projectPerWorkspaceAdditionalSubscriptions"
                                                            :key="i">


                                                            <v-list-item-content>
                                                                <v-list-item-title
                                                                    v-text="item.name"></v-list-item-title>
                                                            </v-list-item-content>

                                                            <v-list-item-icon>
                                                                <span class="font-weight-bold"> {{ item.amount }}</span>
                                                            </v-list-item-icon>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <div class="white next-step-container">
                <v-row>
                    <v-col cols="12" md="3" lg="4">
                        <div class="px-2 px-md-5">
                            <p class="mb-0">Plan </p>
                            <div>
                                <span class="font-weight-bold">{{ pricing.name }}</span>
                            </div>
                            <div>
                                <span class="font-weight-bold">${{ unitAmount }} / {{ selectedBillingCycle }}</span>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3" lg="4">
                        <div class="px-2 px-md-5">
                            <p class="mb-0">Total </p>

                            <div>
                                <span class="font-weight-bold">${{ totalAmount }} </span>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="4" class="d-flex align-center ">
                        <div class="px-2 px-md-5 ">

                            <div>
                                <v-btn :loading="addToShopingCartloading" @click="addToShopingCart"
                                    class="rounded red white--text px-5 py-3 text-center  font-weight-bold"
                                    :class="`${userShopingCart.countItem > 0 ? 'blue' : 'red'}`">
                                    {{ userShopingCart.countItem > 0 ? "Update cart content" : "Add to cart" }}

                                    <v-icon class="ml-2">mdi-cart-plus</v-icon>
                                </v-btn>
                                <v-btn v-if="userShopingCart.countItem > 0" :loading="addToShopingCartloading"
                                    @click="goTo('/checkoutshopingcart')"
                                    class="rounded green white--text px-5 py-3 ml-2 text-center  font-weight-bold">
                                    Checkout

                                    <v-icon class="ml-2">mdi-cart-outline</v-icon>
                                </v-btn>

                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>
<script>
import { methods, rules } from "@/utils/constant.js";
import { mapGetters, mapActions } from "vuex";

import PrincingDetail from "../../components/user/PrincingDetail.vue";

import {
    addToShopingCart,
} from "@/api/user.js";
export default {
    props: {

    },
    components: {
        PrincingDetail
    },
    data() {
        return {
            quantity: 1,
            rules: rules,
            loading: false,
            billingCycle: ["month", "quarterly", "year"],
            selectedBillingCycle: "month",
            pricingNames: [],
            selectedPricingId: this.$route.params.id,
            selectedPricingName: null,
            workspaceAdditionalSubscriptions: [{
                _id: "free",
                name: "2",
                amount: "FREE",
                comment: "2 workspaces"
            },
            {
                _id: "four",
                name: "4",
                amount: "7",
                comment: "4 workspaces"

            }
            ],
            selectedMaxWorkspace: 0,
            memberPerWorkspaceAdditionalSubscriptions: [{
                name: "2",
                amount: "FREE",
                comment: "2 Members per workspace"

            },
            {
                name: "4",
                amount: "7",
                comment: "4 Members per workspace"

            }
            ],
            projectPerWorkspaceAdditionalSubscriptions: [{
                name: "2",
                amount: "FREE",
                comment: "2 Projects per workspace"

            },
            {
                name: "4",
                amount: "7",
                comment: "4 Projects per workspace"

            }
            ],
            selectedMaxMemberPerWorkspace: 0,
            // totalAmount:0,
            pricingAmount: 0,
            selectedMaxProjectPerWorkspace: 0,
            selectedMaxWorkspaceObject: null,
            selectedMaxMemberPerWorkspaceObject: null,
            selectedMaxProjectPerWorkspaceObject: null,
            addToShopingCartloading: false,
        };
    },
    watch: {

        pricings() {
            this.pricingNames = this.pricings.map(item => item.name)
            this.pricingNames = this.pricingNames.filter((name) => name.toLowerCase() != 'free')

        },
        pricing() {
            this.updateSelectedPricing()
        },
        selectedBillingCycle() {
            this.updateSelectedPricing()
        },
        selectedMaxWorkspace() {
            let i = 0
            for (let item of this.pricing.workspaceAdditionalSubscriptions) {
                if (i == this.selectedMaxWorkspace) {
                    this.selectedMaxWorkspaceObject = item

                    break
                }
                i++
            }
        },
        selectedMaxProjectPerWorkspace() {
            let i = 0
            for (let item of this.pricing.projectPerWorkspaceAdditionalSubscriptions) {
                if (i == this.selectedMaxProjectPerWorkspace) {
                    this.selectedMaxProjectPerWorkspaceObject = item

                    break
                }
                i++
            }
        },
        selectedMaxMemberPerWorkspace() {
            let i = 0
            for (let item of this.pricing.memberPerWorkspaceAdditionalSubscriptions) {
                if (i == this.selectedMaxMemberPerWorkspace) {
                    this.selectedMaxMemberPerWorkspaceObject = item

                    break
                }
                i++
            }
        },


        selectedPricingName() {
            this.selectedPricingId = methods.getItemId(
                this.pricings,
                this.selectedPricingName,
                "name",
                "_id"
            )
            // this.updateSelectedPricing()
        },
        selectedPricingId() {
            this.initialize()
        }


    },
    computed: {
        ...mapGetters([
            "pricing",
            "userShopingCart",
            "pricings",
        ]),

        details() {
            const details = []

            if (this.selectedMaxWorkspaceObject) {
                details.push(this.selectedMaxWorkspaceObject)
            }
            if (this.selectedMaxMemberPerWorkspaceObject) {
                details.push(this.selectedMaxMemberPerWorkspaceObject)
            }
            if (this.selectedMaxProjectPerWorkspaceObject) {
                details.push(this.selectedMaxProjectPerWorkspaceObject)
            }
            return details
        },

        unitAmount() {
            let total = Math.ceil(this.pricingAmount + this.unitAdditionalWorkspaceAmount)

            return total
        },
        totalAmount() {
            let total = this.unitAmount
            if (Number.isInteger(Number(Math.abs(this.quantity)))) {
                total = Math.abs(this.quantity) * total
            }
            return total
        },
        unitAdditionalWorkspaceAmount() {
            let total = 0
            if (this.selectedMaxWorkspaceObject && this.selectedMaxWorkspaceObject.amount) {
                if (!isNaN(Number(this.selectedMaxWorkspaceObject.amount))) {
                    if (this.selectedBillingCycle == 'quarterly' && !isNaN(Number(this.selectedMaxWorkspaceObject.quaterlyAmount))) {
                        total += Number(this.selectedMaxWorkspaceObject.quaterlyAmount)
                    }
                    else if (this.selectedBillingCycle == 'year' && !isNaN(Number(this.selectedMaxWorkspaceObject.yearlyAmount))) {
                        total += Number(this.selectedMaxWorkspaceObject.yearlyAmount)
                    }
                    else {
                        total += Number(this.selectedMaxWorkspaceObject.amount)
                    }

                }
            }
            if (this.selectedMaxMemberPerWorkspaceObject && this.selectedMaxMemberPerWorkspaceObject.amount) {
                if (!isNaN(Number(this.selectedMaxMemberPerWorkspaceObject.amount))) {
                    if (this.selectedBillingCycle == 'quarterly' && !isNaN(Number(this.selectedMaxMemberPerWorkspaceObject.quaterlyAmount))) {
                        total += Number(this.selectedMaxMemberPerWorkspaceObject.quaterlyAmount)

                    }
                    else if (this.selectedBillingCycle == 'year' && !isNaN(Number(this.selectedMaxMemberPerWorkspaceObject.yearlyAmount))) {
                        total += Number(this.selectedMaxMemberPerWorkspaceObject.yearlyAmount)
                    } else {
                        total += Number(this.selectedMaxMemberPerWorkspaceObject.amount)

                    }
                }
            }
            if (this.selectedMaxProjectPerWorkspaceObject && this.selectedMaxProjectPerWorkspaceObject.amount) {
                if (!isNaN(Number(this.selectedMaxProjectPerWorkspaceObject.amount))) {
                    if (this.selectedBillingCycle == 'quarterly' && !isNaN(Number(this.selectedMaxProjectPerWorkspaceObject.quaterlyAmount))) {
                        total += Number(this.selectedMaxProjectPerWorkspaceObject.quaterlyAmount)

                    }
                    else if (this.selectedBillingCycle == 'year' && !isNaN(Number(this.selectedMaxProjectPerWorkspaceObject.yearlyAmount))) {
                        total += Number(this.selectedMaxProjectPerWorkspaceObject.yearlyAmount)
                    } else {
                        total += Number(this.selectedMaxProjectPerWorkspaceObject.amount)

                    }
                }
            }
            return Math.ceil(total)
        },
        additionalWorkspaceAmount() {
            let total = 0
            if (this.selectedMaxWorkspaceObject && this.selectedMaxWorkspaceObject.amount) {
                if (!isNaN(Number(this.selectedMaxWorkspaceObject.amount))) {
                    if (this.selectedBillingCycle == 'quarterly' && !isNaN(Number(this.selectedMaxWorkspaceObject.quaterlyAmount))) {
                        total += Number(this.selectedMaxWorkspaceObject.quaterlyAmount)
                    }
                    else if (this.selectedBillingCycle == 'year' && !isNaN(Number(this.selectedMaxWorkspaceObject.yearlyAmount))) {
                        total += Number(this.selectedMaxWorkspaceObject.yearlyAmount)
                    }
                    else {
                        total += Number(this.selectedMaxWorkspaceObject.amount)
                    }

                }
            }
            if (this.selectedMaxMemberPerWorkspaceObject && this.selectedMaxMemberPerWorkspaceObject.amount) {
                if (!isNaN(Number(this.selectedMaxMemberPerWorkspaceObject.amount))) {
                    if (this.selectedBillingCycle == 'quarterly' && !isNaN(Number(this.selectedMaxMemberPerWorkspaceObject.quaterlyAmount))) {
                        total += Number(this.selectedMaxMemberPerWorkspaceObject.quaterlyAmount)

                    }
                    else if (this.selectedBillingCycle == 'year' && !isNaN(Number(this.selectedMaxMemberPerWorkspaceObject.yearlyAmount))) {
                        total += Number(this.selectedMaxMemberPerWorkspaceObject.yearlyAmount)
                    } else {
                        total += Number(this.selectedMaxMemberPerWorkspaceObject.amount)

                    }
                }
            }
            if (this.selectedMaxProjectPerWorkspaceObject && this.selectedMaxProjectPerWorkspaceObject.amount) {
                if (!isNaN(Number(this.selectedMaxProjectPerWorkspaceObject.amount))) {
                    if (this.selectedBillingCycle == 'quarterly' && !isNaN(Number(this.selectedMaxProjectPerWorkspaceObject.quaterlyAmount))) {
                        total += Number(this.selectedMaxProjectPerWorkspaceObject.quaterlyAmount)

                    }
                    else if (this.selectedBillingCycle == 'year' && !isNaN(Number(this.selectedMaxProjectPerWorkspaceObject.yearlyAmount))) {
                        total += Number(this.selectedMaxProjectPerWorkspaceObject.yearlyAmount)
                    } else {
                        total += Number(this.selectedMaxProjectPerWorkspaceObject.amount)

                    }
                }
            }
            return Math.ceil(total) * Math.abs(this.quantity)
        },
    },
    methods: {
        onQuantityChange() {
            // console.log("quantity",this.quantity)
            if (this.quantity < 0) {
                alert("quantity can not be negative")
                this.quantity = 0
            }
        },
        ...mapActions([
            "getPricing",
            "getPricings",
            "getMyShopingCart",
        ]),

        updateSelectedPricing() {
            this.selectedPricingName = this.pricing.name
            this.selectedPricingId = this.pricing._id
            this.pricingAmount = this.pricing.amount
            if (this.selectedBillingCycle == 'quarterly') {
                this.pricingAmount = this.pricing.quaterlyAmount
            }
            if (this.selectedBillingCycle == 'year') {
                this.pricingAmount = this.pricing.yearlyAmount
            }
        },
        async initialize() {
            try {
                this.loading = true;
                await this.getPricings();

                await this.getPricing({
                    id: this.selectedPricingId,
                });
                this.selectedMaxMemberPerWorkspaceObject = null
                this.selectedMaxProjectPerWorkspaceObject = null
                this.selectedMaxWorkspaceObject = null

                this.selectedMaxProjectPerWorkspace = 0
                this.selectedMaxMemberPerWorkspace = 0
                this.selectedMaxWorkspace = 0

                if (Array.isArray(this.pricing.memberPerWorkspaceAdditionalSubscriptions) && this.pricing.memberPerWorkspaceAdditionalSubscriptions[0]) {
                    this.selectedMaxMemberPerWorkspaceObject = this.pricing.memberPerWorkspaceAdditionalSubscriptions[0]
                }
                if (Array.isArray(this.pricing.projectPerWorkspaceAdditionalSubscriptions) && this.pricing.projectPerWorkspaceAdditionalSubscriptions[0]) {
                    this.selectedMaxProjectPerWorkspaceObject = this.pricing.projectPerWorkspaceAdditionalSubscriptions[0]
                }
                if (Array.isArray(this.pricing.workspaceAdditionalSubscriptions) && this.pricing.workspaceAdditionalSubscriptions[0]) {
                    this.selectedMaxWorkspaceObject = this.pricing.workspaceAdditionalSubscriptions[0]
                }

            } catch (error) {
                console.log("initialize home", error);
                methods.dispatchError(this.$store);
            } finally {
                this.loading = false;

            }
        },
        async addToShopingCart() {
            try {
                this.addToShopingCartloading = true;
                await addToShopingCart({
                    schema: {
                        billingCycle: this.selectedBillingCycle,
                        quantity: Math.abs(this.quantity),
                        amount: this.totalAmount,
                        custormMetaDeta: {},
                        pricing: this.pricing._id,
                        memberPerWorkspaceAdditionalSubscriptionCustomization: this.selectedMaxMemberPerWorkspaceObject._id,
                        projectPerWorkspaceAdditionalSubscriptionCustomization: this.selectedMaxProjectPerWorkspaceObject._id,
                        workspaceAdditionalSubscriptionCustomization: this.selectedMaxWorkspaceObject._id,
                    }
                })

                await this.getMyShopingCart()

                methods.dispatchSuccess(this.$store, this.userShopingCart.countItem > 0 ? "Cart content updated successfully. Click on the cart icon in the navigation bar to checkout your order." : "Product added to cart successfully. Click on the cart icon in the navigation bar to checkout your order.");



            } catch (error) {
                console.log("initialize home", error);
                methods.dispatchError(this.$store);
            } finally {
                this.addToShopingCartloading = false;

            }
        },
        goTo(link) {
            this.$router.push(link);
        },
        onSuccess() {
            this.initialize();
            this.$emit("refresh");
        },

    },

    mounted() {
        this.initialize()
    },
};
</script>

<style>
.step1-pricing {
    font-size: 2rem;
    font-weight: 900;
    margin-left: .5rem;
}

.next-step-container {
    position: sticky;
    bottom: 0;
    /* or any other value */

}
</style>